import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import useModal from '@/hooks/useModal'
import { useTranslation } from 'react-i18next'
import Button from '@libs/Buttons/Button'
import LazyLoad from 'react-lazyload'
import logo from './img/logo'
import facebook from './img/facebook'
import instagram from './img/instagram'
import linkedin from './img/linkedin'
import classes from './classes.module.scss'

export default function Footer() {
  const { pathname } = useLocation()
  const { changeSettings } = useModal()
  const { t } = useTranslation()

  const onClickHandler = e => {
    const {
      type,
      mark,
    } = e.target.dataset

    changeSettings(true, {
      type,
      mark,
    })
  }

  const footerContent = <div className={classes.footer}>
    <div className="landing_component_wrapper">
      <div className={classes.content}>
        <div className={classes.images}>
          <div>
            <img src={logo} alt="logo" width="137" />
          </div>
          <div className={classes.social_networks}>
            <a href="https://www.facebook.com/yolostudyonline" target="_blank" rel="noreferrer">
              <img src={facebook} alt="facebook" height="26" />
            </a>
            <a href="https://www.instagram.com/yolo.study.on" target="_blank" rel="noreferrer">
              <img src={instagram} alt="instagram" height="26" />
            </a>
            <a href="https://www.linkedin.com/company/yolo-study-online/about/" target="_blank" rel="noreferrer">
              <img src={linkedin} alt="linkedin" height="26" />
            </a>
          </div>
        </div>
        <ul>
          <li><a href="mailto:yolostudyonline@gmail.com">yolostudyonline@gmail.com</a></li>
          <li><a href="tel:+380955838283">+38 095 583 82 83</a></li>
          <li>
            <Button
              color="purple"
              source="landing"
              onClick={onClickHandler}
              data-type="registration"
              data-mark="Footer"
            >
              {t('buttons:try_free')}
            </Button>
          </li>
        </ul>
        <ul>
          <li><Link to="/english_for_teens">{t('navigation:children_and_teenagers')}</Link></li>
          <li><Link to="/teachers">{t('navigation:teachers')}</Link></li>
          <li><Link to="/about">{t('navigation:school')}</Link></li>
          <li><Link to="/articles">{t('navigation:blog')}</Link></li>
        </ul>
        <ul>
          <li><Link to="/privacy_policy">{t('navigation:privacy_policy')}</Link></li>
          {/* <li><Link to="/public_offer">{t('navigation:public_offer')}</Link></li> */}
          <li><Link to="/website_conditions">{t('navigation:website_conditions')}</Link></li>
          <li><Link to="/payment">{t('navigation:payment')}</Link></li>
          <li><Link to="/vacancies">{t('navigation:vacancies')}</Link></li>
          <li><Link to="/advertising">{t('navigation:marketing')}</Link></li>
        </ul>
      </div>
    </div>
  </div>

  return (
    <footer>
      {
        pathname === '/articles' || pathname === '/payment' || pathname === '/vacancies' || pathname === '/advertising'
          ? footerContent
          : <LazyLoad offset={100}>
            {footerContent}
          </LazyLoad>
      }
    </footer>
  )
}